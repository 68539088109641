<template>
  <div class="bg-slate-100">
    <div
      class="relative border-slate-300 border mx-auto max-w-2xl flex flex-col w-full items-stretch border-b border-sand"
    >
      <div
        v-if="authStore.line"
        class="flex items-center justify-between border-b border-gray-300 shadow border-marble h-16 bg-white"
      >
        <div class="flex flex-row items-center mx-4">
          <span class="text-lg text-gray-900 font-semibold">PhotoWish  </span>
          <span class=" ml-2 text-lg text-gray-600"> Buy Package  </span>
        </div>
        <div
          class="flex items-center mx-2 gap-x-2 relative flex-shrink-0"
        >
        <span class=" ml-2 text-lg text-gray-600"> [ID : {{ eventId }}]  </span>

      </div>
      </div>

      <div class="min-h-screen pb-16">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
const route = useRoute();
const authStore = useAuthStore();
const appConfig = useAppConfig();
const eventStore = useEventStore();

const eventId = computed(() => {
  return eventStore?.eventId  || ""
});

onBeforeMount(async () => {
  if (route.query.codeId) {   
    localStorage.setItem("partner_codeId", route.query.codeId);
    localStorage.setItem("codeId", route.query.codeId);
    localStorage.setItem("partnerId", route.query.partnerId);
  }  
  const LIFF_ID = appConfig.LIFF_ID_PARTNER  
  let destinationUrl = window.location.origin + window.location.pathname;
  await authStore.lineSignIn(LIFF_ID, destinationUrl);
});
</script>
